import { BadgeProps as BadgePropsType } from "./components/Badge";

/**
 * Defines props shared between both full & dot icons
 */
export interface SharedIconProps {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    colorParams?: ColorParams;
    badgeParams?: BadgeParams;
    progressRingParams?: ProgressRingParams;
    size: number;
    dotSize?: number;
    dimmed?: boolean;
}
export type ProgressRingVariant =
    | {
          spinning: true;
          percentage: number;
      }
    | {
          spinning: false;
          percentage?: number;
      };

export type ProgressRingParams =
    | {
          progressRing?: true;
          progressRingOptions: ProgressRingVariant;
      }
    | {
          progressRing?: false;
          progressRingOptions?: ProgressRingVariant;
      };

export type BadgeParams =
    | {
          badge?: true;
          badgeOptions: BadgePropsType;
      }
    | {
          badge?: false;
          badgeOptions?: BadgePropsType;
      };

/**
 * Defines available colors for each icon variant
 */
export type ColorParams = {
    shadow: string;
    iconBackground: string;
    border?: string;
    shadowStyle?: SHADOW_STYLE;
    iconColor?: string;
};

export enum SHADOW_STYLE {
    SPARSE = "SPARSE",
    DENSE = "DENSE",
}

/**
 * Defines specific icon variants, preset groups of settings
 */
export enum ICON_VARIANT {
    SENSOR = "sensor",
    VALVE = "valve",
    GROUP = "group",
}

/**
 * Types of icon graphics
 */
export enum DEVICE_ICON_GRAPHICS {
    SENSOR = "sensor",
    VALVE = "valve",
    GROUP = "group",
    MANUAL_MODE = "manualMode",
    DRAGINO_GATEWAY = "draginoGateway",
}

/**
 * Types of warnings possible
 */
export enum WARNING_STATUS {
    ERROR = "Error",
    WARNING = "Warning",
}

/**
 * Types of badges
 */
export enum BADGE_VARIANT {
    ERROR = WARNING_STATUS.ERROR,
    WARNING = WARNING_STATUS.WARNING,
}

/**
 * Icon size scaling options
 */
export enum SIZES {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
}

/**
 * Colors that can be overridden by users via the notes field
 */
export enum NOTES_OVERRIDE_COLOR {
    BLUE = "blue",
    TEAL = "teal",
    YELLOW = "yellow",
    PURPLE = "purple",
    PINK = "pink",
    BROWN = "brown",
    INDIGO = "indigo",
}
