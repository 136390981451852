import CellTowerIcon from "@mui/icons-material/CellTower";
import * as React from "react";

export default function DraginoGatewayIcon(props) {
    // Dragino Gateway Icon (DL0S8N)
    return (
        <>
            <CellTowerIcon style={{ fill: "black" }} {...props} />
        </>
    );
}
